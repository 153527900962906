<template>
  <div id="GiveAward">
    <!-- notification -->
    <v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true">
      {{ notificationMsg }}
      <v-btn dark text @click="notificationToggle = false">Close</v-btn>
    </v-snackbar>

    <!-- add form -->
    <v-form @submit.prevent="GiveAward">
      <v-container class="pa-5">
        <v-card class="pa-2 mt-5 elevation-5">
          <v-card-title>
            <h3>Give Award</h3>
          </v-card-title>
          <v-card-text class="pa-2">
            <v-row>
              <!-- add form -->
              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  label="Month"
                  :items="months"
                  item-text="name"
                  item-value="value"
                  v-model="Month"
                  :error="$v.Month.$error"
                  :error-messages="MonthErrors"
                  @input="$v.Month.$touch()"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  type="tel"
                  label="Year"
                  v-model="Year"
                  v-mask="mask"
                  :error="$v.Year.$error"
                  :error-messages="YearErrors"
                  @input="$v.Year.$touch()"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="Award"
                  :items="GetAwards"
                  item-text="title"
                  item-value="slug"
                  label="Award"
                  persistent-hint
                  return-object
                  single-line
                  :error="$v.Award.$error"
                  :error-messages="AwardErrors"
                  @input="$v.Award.$touch()"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-btn type="submit" :disabled="disbtn" class="success elevation-10">Give Award</v-btn>
            <v-btn @click="$router.go(-1)" class="secondary elevation-10">back</v-btn>
          </v-card-actions>

          <!-- show overlay when add item -->
          <v-fade-transition>
            <v-overlay absolute :value="disbtn">
              <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </v-container>
    </v-form>

    <!-- award list -->
    <v-container class="pa-5">
      <v-card class="pa-2 mt-5 elevation-5">
        <v-card-title>
          <h3>Award List</h3>
        </v-card-title>
        <v-card-text class="pa-2 mb-5">
          <div class="px-5 pb-5">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              solo
            ></v-text-field>
          </div>
          <!-- table properties  -->
          <v-data-table
            :headers="tableData.headers"
            :search="search"
            :loading="loadingFlag"
            loading-text="Filling Up Realtime Data From Cloud..."
            :items="tableData.Data"
            height="300"
            class="elevation-2 mx-5"
            multi-sort
          >
            <!-- show if no data is present -->
            <template v-slot:no-data>
              <h2 class="mt-5">No Records!</h2>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// imports
import { required, minLength } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

// scripts
export default {
  name: "GiveAward",

  mounted() {
    setTimeout(() => {
      this.timer = true;
    }, 5000);
  },

  data() {
    return {
      // app flags
      notificationToggle: false,
      notificationMsg: "hai",
      notificationType: "hui",
      disbtn: false,
      search: "",
      timer: false,
      tableMonth: "",
      tableAwardName: "",
      selected: [],
      // table headers
      headers: [
        { text: "Award", value: "award" },
        { text: "Month", value: "month" },
        { text: "Year", value: "year" }
      ],
      // logic data
      Month: "",
      Year: new Date().getFullYear().toString(),
      Award: [],
      mask: "####",
      months: [
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
        { name: "January", value: 1 },
        { name: "February", value: 2 }
      ]
    };
  },

  methods: {
    // add to db
    GiveAward() {
      // blank validation
      if (!this.BlankValidation()) {
        return false;
      }
      this.disbtn = true;

      // set a quarter based on month
      let awardquarter = "";
      if (Number(this.Month)) {
        if (
          Number(this.Month) == 6 ||
          Number(this.Month) == 7 ||
          Number(this.Month) == 8
        ) {
          awardquarter = "Q1";
        } else if (
          Number(this.Month) == 9 ||
          Number(this.Month) == 10 ||
          Number(this.Month) == 11
        ) {
          awardquarter = "Q2";
        } else {
          awardquarter = "Q3";
        }
      }
      // actual add statement
      // Add a new document with a generated id.
      firebase
        .firestore()
        .collection("studentAwards")
        .add({
          awardslug: this.Award.awardslug,
          studentID: Number(this.StudentId),
          quarter: awardquarter,
          month: Number(this.Month),
          year: Number(this.Year)
        })
        .then(doc => {
          this.ResetFields();
          this.disbtn = false;
          this.notificationService("Successfully Added!", "success");
          this.$store.dispatch("getStudentAwards");
        })
        .catch(err => {
          console.log(err);
        });
    },

    ShowDetails(data) {
      if (data.awardslug != null) {
        this.$router.push("/awarddetails/" + data.awardslug);
      }
    },

    // reset fields
    ResetFields() {
      (this.Award = []), this.$v.$reset();
    },

    // check if all fileds are empty
    BlankValidation() {
      if (this.studentID == "" || this.Year == "" || this.Award.length == 0) {
        this.notificationService("Fill All Fields!", "red darken-1");
        return false;
      } else {
        return true;
      }
    },

    // error pusher for vuelidate
    ErrorPusher(basic, extra, msg) {
      const errors = [];
      if (!basic) return errors;
      !extra && errors.push(msg);
      return errors;
    },

    // notification service
    notificationService(msg, type) {
      this.notificationType = type;
      this.notificationMsg = msg;
      this.notificationToggle = true;
    }
  },

  // vue-the-mask related stuff
  directives: { mask },

  // validations
  validations: {
    Award: { required },
    Month: { required },
    Year: { required, minLength: minLength(4) }
  },

  watch: {
    SelectedData(newvalue) {
      this.SelectedData != null ? this.ShowDetails(this.SelectedData) : null;
    }
  },

  computed: {
    StudentId() {
      return this.$route.params.id != null ? this.$route.params.id : null;
    },

    StudentData() {
      if (this.StudentId != null) {
        let data = this.$store.getters.GetStudentWithID(this.StudentId);
        if (data != undefined) {
          if (data.length != 0) {
            return data[0];
          } else {
            this.$router.push("/students");
          }
        } else {
          this.$router.push("/students");
        }
      } else {
        this.$router.push("/students");
      }
    },

    GetAwards() {
      return this.$store.getters.GetAllAwards;
    },

    GetStudentAwards() {
      if (this.StudentId && this.StudentData) {
        return this.$store.getters.GetStudentAwardWithID(this.StudentId);
      }
    },

    FormatTableData() {
      if (this.GetStudentAwards && this.GetAwards) {
        let TmptableData = [];
        this.GetStudentAwards.map(gs => {
          // get award data based on slug
          let data = this.$store.getters.GetAwardWithSlug(gs.awardslug);
          let awardname = data.length != 0 ? data[0].title : null;
          // get month name based on number
          let data2 = this.months.filter(m => m.value == gs.month);
          let monthname = data2.length != 0 ? data2[0].name : null;
          TmptableData.push({
            awardslug: gs.awardslug,
            month: monthname,
            award: awardname,
            year: gs.year
          });
        });
        return TmptableData;
      }
    },

    tableData() {
      return { headers: this.headers, Data: this.FormatTableData };
    },

    loadingFlag() {
      if (!this.tableData) {
        if (this.timer) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    SelectedData() {
      const selectedRow = this.selected[0];
      return selectedRow ? selectedRow : null;
    },

    AwardErrors() {
      return this.ErrorPusher(
        this.$v.Award.$dirty,
        this.$v.Award.required,
        "Award is Required"
      );
    },
    MonthErrors() {
      return this.ErrorPusher(
        this.$v.Month.$dirty,
        this.$v.Month.required,
        "Month is Required"
      );
    },
    YearErrors() {
      return this.ErrorPusher(
        this.$v.Year.$dirty,
        this.$v.Year.minLength,
        "Year is Required (YYYY)"
      );
    }
  }
};
</script>

<style scoped>
</style>